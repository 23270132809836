.surveyHeading {
  font-size: 21px;
  margin-bottom: 40px;
}

.teethTypeWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;
  justify-content: center;
}

.teethTypeItem {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  width: 140px;
  min-height: 65px;
}

.teethTypeItemContent {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 10px 16px;
  overflow-wrap: break-word;
  word-break: break-word;
  background-color: rgba(63, 63, 63, 0.1);
  font-size: 13px;
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-delay: 0s;
}

.teethTypeItemContentIsSelected {
  background-color: rgba(63, 63, 63, 0.8);
  color: #fff;
}

.teethTypeItemSelectionMark {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-delay: 0s;
}

.teethTypeItemSelectionMark::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-top: 50px solid #000;
  border-left: 50px solid transparent;
  border-top-color: rgb(63, 63, 63);
}

.selectionMarkIsSelected {
  opacity: 1;
}

.iconStyles {
  position: absolute;
  top: 9px;
  right: 2px;
  height: 12px;
  opacity: 0.5;
}

.teethTypeImageContainer {
  width: 140px;
  height: 64px;
}

.teethTypeImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .teethTypeWrapper {
        column-gap: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 375px) {
    .teethTypeWrapper {
        column-gap: 10px;
    }

    .teethTypeItem {
        width: 120px;
    }

    .teethTypeImageContainer {
        width: 100%;
    }
}
