.surveyHeading {
  font-size: 21px;
  margin-bottom: 40px;
}

.answersWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.btnAnswer {
  margin-bottom: 5px;
  margin-right: 15px;
  font-family: 'Roboto';
  text-align: left;
  margin-bottom: 8px;
  font-size: 13px;
  color: rgb(63, 63, 63);
  cursor: pointer;
  border: 1px solid rgba(63, 63, 63, 0.5);
  background-color: #fff;
  border-radius: 10px;
  min-width: 18em;
  padding: 10px;
  outline: none;
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1)
}

.btnAnswerSelected {
  color: rgb(255, 255, 255);
  background-color: rgba(63, 63, 63, 0.8) !important;
}

.btnAnswerSelected {
  color: rgb(255, 255, 255);
  background-color: rgba(63, 63, 63, 0.8) !important;
}

.btnAnswer:hover {
  background-color: rgba(63, 63, 63, 0.2);
}
