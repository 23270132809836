.form {
  flex: 1;
}
.surveyHeading {
  font-size: 19px;
  margin-bottom: 40px;
}
.input {
  font-family: 'Roboto';
  width: 100%;
  max-width: 70%;
  font-size: 19px;
  border: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 15px;
  padding-bottom: 8px;
  outline: none;
}

@media screen and (min-width: 0) and (max-width: 767px) {
    .input {
        max-width: 100%;
    }
}
