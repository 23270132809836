.form {
  flex: 1;
}
.surveyHeading {
  font-size: 19px;
  margin-bottom: 40px;
}
.inputWrapper {
  position: relative;
  margin-bottom: 15px;
}

.input {
  font-family: "Roboto";
  width: 100%;
  max-width: 70%;
  font-size: 19px;
  border: none;
  border-bottom: 1px solid #747474;
  margin-bottom: 15px;
  padding-bottom: 8px;
  outline: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
    .input {
        max-width: 100%;
        font-size: 17px;
    }
}
.inputError {
  color: rgb(229, 85, 79);
  border-bottom: 1px solid rgb(229, 85, 79);
}

.errorWrapper {
  position: absolute;
  top: 35px;
  bottom: -5px;
  display: flex;
}

.errorText {
  font-size: 13px;
  color: rgb(229, 85, 79);
  margin-left: 10px;
  margin-right: 10px;
}
