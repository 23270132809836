.wrapper {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 30px;
    margin-bottom: 30px;
    column-gap: 10px;
}

.arabic_wrapper {
    display: flex;
    position: fixed;
    bottom: -10px;
    left: 35px;
    margin-right: 30px;
    margin-bottom: 30px;
    column-gap: 10px;
}

.button {
    width: 42px;
    height: 42px;
    padding: 0;
    border-radius: 50%;
    border: solid 2px #888589;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
}

.button:hover {
    border: solid 2px #767377;
}

.button:last-child {
    margin-right: 0;
}

.buttonDisabled {
    opacity: 0.3;
}

.button > svg {
    color: yellow;
}

@media screen and (min-width: 0) and (max-width: 767px) {
    .wrapper {
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
