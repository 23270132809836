.app-container {
  padding-left: 18%;
  padding-right: 18%;
  min-height: 78vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .app-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .app-container {
    padding-left: 10%;
    padding-right: 10%;
  }
}
