.container {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.surveyHeading {
  font-family: 'Roboto', serif;
  color: rgb(57, 57, 57);
  font-size: 21px;
  font-weight: 700;
  font-style: normal;
}
.videoContainer {
  height: 0;
  max-height: 380px;
  padding-bottom: 55%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
}
.videoContainer > iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
