.surveyHeading {
  font-size: 21px;
  margin-bottom: 40px;
}

.answersWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  column-gap: 24px;
}

.answerOption {
  width: 60%;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
  height: 185px;
  background-color: #ebebeb;
  margin-bottom: 20px;
}

.answerOptionSelected {
  color: rgb(255, 255, 255);
  background-color: rgba(63, 63, 63, 0.8) !important;
}

.answerOption:hover {
  background-color: #D9D9D9;
}

.answerOption > svg {
  transition: all 0.3s;
}

.answerOption:hover > svg {
  transform: scale(1.2);
}

.answerText {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px 15px;
}
