.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: transparent;
    box-shadow: 2px 1px 6px rgb(38 38 39 / 12%);
    padding: 0 20px;
}

.centerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: transparent;
    box-shadow: 2px 1px 6px rgb(38 38 39 / 12%);
    padding: 0 20px;
}

.headerLogo {
    width: 155px;
    height: 28px;
    max-width: 100%;
    max-height: 100%;
}

@media screen and (min-width: 0) and (max-width: 490px) {
    .headerLogo {
        margin: 0 auto 0 3%;
    }
}
