.wrapper {
    width: 100%;
}

.text {
    max-width: 700px;
    margin: auto;
    font-size: 42px;
    text-align: center;
}

.icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 25px;
}

.socialMediaIcon {
    cursor: pointer;
    width: 54px;
    height: 54px;
}

.socialMediaIcon > path,
.socialMediaIcon > g > path {
    fill: #e5554f;
}

.socialMediaIcon:hover path,
.socialMediaIcon:hover > g > path {
    fill: #c9241d;
}

@media screen and (min-width: 0) and (max-width: 425px) {
    .text {
        font-size: 23px;
    }

    .icons {
        column-gap: 10px;
    }

    .socialMediaIcon {
        width: 37px;
        height: 37px;
    }
}
