.submitButton {
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 100px;
    border: 2px solid rgb(229, 85, 79);
    color: rgb(229, 85, 79);
    cursor: pointer;
    display: flex;
    font-family: "Roboto", serif;
    font-size: 14px;
    font-weight: 700;
    justify-content: space-between;
    min-width: 150px;
    overflow: hidden;
    padding: 16px 24px;
    pointer-events: all;
    position: relative;
    text-transform: uppercase;
    transition: all 0.3s;
    will-change: transform;
}

.submitButton:before {
    border-radius: 100px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 0.3s;
    transform: translateX(-100%);
    -webkit-transition: transform 0.3s;
    -moz-transition: transform 0.3s;
    width: 100%;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

.submitButton:hover {
    background-color: rgb(229, 85, 79);
    color: rgb(255, 255, 255);
}

.submitButton:hover:before {
    transform: translateX(0%);
}

.submitButton .arrow path {
    stroke: rgb(229, 85, 79);
}

.submitButton:hover .arrow path {
    stroke: rgb(255, 255, 255);
}

.submitButtonDisabled {
    cursor: not-allowed;
    opacity: 0.3;
}

@media screen and (max-width: 767px) {
  .submitButton {
    min-width: 7.5em;
  }
}
