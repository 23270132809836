.question {
  font-size: 21px;
  margin-bottom: 5px;
}

.statusUpdate {
  color: rgb(91, 91, 91);
  margin-bottom: 30px;
}

#phoneInputWrapper {
  position: relative;
}

.phoneInputContainer {
  padding-bottom: 10px;
  margin-bottom: 25px;
  display: flex;
  column-gap: 20px;
}

.phoneInputContainer .phoneInput {
  font-size: 16px;
    font-family: 'Roboto', serif;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-bottom: 1px solid #747474;
  padding-left: 0;
  order: 2;
    direction: ltr;
}

.phoneInputContainer .phoneButton {
  position: static;
  border: none;
  background-color: #EBEBEB;
  border-radius: 6px;
}

#phoneInputWrapper .phoneInputContainer .phoneButton {
  border: none;
  background-color: #EBEBEB;
  border-radius: 6px;
}

#phoneInputWrapper .phoneInputContainer .phoneButton > div {
  width: 53px;
}

#phoneInputWrapper .phoneInputContainer.rtl .phoneButton > div {
  padding: 0 8px 0 0;
    text-align: right ;
}

#phoneInputWrapper .phoneInputContainer > div > div{
  background-color: #EBEBEB;
  border-radius: 6px;
}

/* dropdown arrow icon */
#phoneInputWrapper .phoneInputContainer .phoneButton > div > div > div {
  position: relative;
  top: 50%;
  margin-top: -6px;
  left: 25px;
  width: 15px;
  height: 15px;
  border: 0;
  background-image: url('../../assets/arrow-icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(90deg);
}

#phoneInputWrapper .phoneInputContainer.rtl .phoneButton > div > div > div {
  right: 25px !important;
  left: 0;
    text-align: right !important;
}

.phoneDropdown > li > div {
    margin-left: 7px;
}

.wrapper {
    width: inherit;
}

@media screen and (min-width: 0) and (max-width: 425px) {
    .wrapper {
        width: 80%;
    }
    
    .phoneInputContainer {
        width: 90%;
    }

    .question {
        width: 90%;
        font-size: 19px;
    }

    .statusUpdate {
        font-size: 13px;
    }
}
