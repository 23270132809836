.container {
  width: 100%;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: -100px;
}

.surveyHeading {
    font-family: 'Roboto', serif;
  color: rgb(57, 57, 57);
  font-size: 21px;
  margin-bottom: 15px;
}

.dropzoneWrapper {
  position: relative;
  padding-bottom: 5px;
}

.dropzoneContainer {
  background-color: rgba(63, 63, 63, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 .25em;
  height: 240px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px dashed #000;
  border-radius: 10px;
}

.dropZoneText {
  font-size: 13px;
  font-weight: 600;
  color: #393939;
  text-align: center;
}

.dropZoneTextSpan {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: rgb(57, 57, 57);
}

.dropZoneTextSpan a {
  color: rgb(57, 57, 57);
}

.link {
  color: #007eff;
  text-decoration: none;
  font-size: 21px;
    margin-top: -5px;
  margin-bottom: 15px;
  display: block;
}

.dropzoneHelper {
  font-size: 11px;
  color: rgba(57, 57, 57, 0.6);
  text-align: center;
}

.successUploadsList {
  margin-left: 20px;
}

.wrapper {
    width: 55%;
    margin: 20px 0;
}

@media screen and (max-width: 570px) {
    .wrapper {
        width: 100%;
    }
}

.preview {
    max-width: 100%;
    display: flex;
    background-color: rgba(63, 63, 63, 0.1);
    font-size: 13px;
    border: 1px solid rgba(63, 63, 63, 0.5);
    color: rgb(57, 57, 57);
    line-height: 1.15;
    font-weight: 400;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 12px;
    border-radius: 3px;
    height: 56px;
    max-height: 56px;
    margin: 16px 0 16px 0;
    overflow: hidden;
}

.preview-image-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    width: 60%;
}

.preview-image {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 3px;
    margin: 0 12px 0 0;
}

.preview-image-name {
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: ellipsis;
}

.preview-size-box {
    width: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.preview-size {
    opacity: 0.7;
    margin: 0 24px 0 0;
    box-sizing: border-box;
}

.uploadIcon {
    width: 85px;
}

@media screen and (min-width: 0px) and (max-width: 425px) {
    .dropzoneContainer {
        height: 100px;
    }

    .uploadIcon {
        width: 50px;
    }

    .dropzoneWrapper {
        margin-bottom: 0
    }
    
    .dropZoneText {
        padding-bottom: 10px;
    }

    .surveyHeading {
        margin-bottom: 0;
    }

    .link {
        margin-top: -15px;
        margin-bottom: 5px;
        font-size: 19px;
    }
}
