.wrapper {
    display: flex;
}

.languageButton {
    background-color: #fff;
    border-radius: 2px;
    border: none;
    outline: none;
    color: #333;
    cursor: pointer;
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 17px;
    padding: 8px;
}

.languageButton:hover {
    box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
}

